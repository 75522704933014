import { useState } from "react";
import CreateDistributor from "./createDistributor";
import "./createdistributormodal.css";
import DistributorForm from "./distributorForm";

const values = [
  { id: 1, text: "Create Distributor" },
];

function CreateDistributorModal() {

  const [panNum, setPanNum] = useState("");
  const [view, setView] = useState("createDistributor");

  const handlePanNumber = (e) => setPanNum(e);

  const ENUM_STATES = {
    createDistributor: <CreateDistributor handlePanNumber={handlePanNumber} setView={setView} />,
    DistributorFormForm: <DistributorForm panNum={panNum} setView={setView} />
  }

  return (
    <>
      {ENUM_STATES[view]}
    </>
  );
}

export default CreateDistributorModal;
