import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomModal from "../../../components/common/customModal/customModal";
import UseForm from "../../../hooks/useForm";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { TransferByNumbervalidation } from "../../../validation/validation";

const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
  // margin: "24px 0px"
};

const initialState = {
  userId: {
    value: "", required: true, regexp: /^[0-9_ ]*$/,
    minLength: 10,
    minLengthMessage: 'At least 10 characters long!',
    maxLength: 10,
    maxLengthMessage: 'Too many characters!'
  },
  amount: {
    value: "", required: true, regexp: /^[1-9][0-9]*$/,
    minLength: 3,
    minLengthMessage: 'At least 3 characters long!',
    maxLength: 7,
    maxLengthMessage: 'Too many characters!'
  },
};

const selectData = [
  { value: "sbi", label: "SBI" },
  { value: "icic", label: "ICIC" },
  { value: "yes bank", label: "YES BANK" },
];

const TransferByNumber = () => {
  const [sendData, setSendData] = useState(null);
  const [state, contextDispatch] = useContext(Context);

  const { handleChange, values, selectChange, handleSubmit, errors, clearState, resetKey } = UseForm(submitForm, TransferByNumbervalidation, initialState);

  function submitForm() {
    setSendData({
      mobileNumber: values.userId.value,
      value: values.amount.value,
    });
    contextDispatch({
      type: Constants.CUSTOM_MODAL,
      payload: { isOpen: true, modalProps: {} },
    });
    clearState(initialState);
  }

  const handleInputChange = (e) => {
    const pattern = /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    }
    else if (e.target.value === "") {
      resetKey(initialState.amount, "amount");
    }
  }

  return (
    <Grid sm={12} md={12}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="flex justify-center mt-5 flex-col">
          <label className="valueTransfer-label my-3 pt-2">
            Enter Phone Number
            <span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            {/* <CustomSelect data={selectData} name="userId" value={values.userId.value} handleChange={selectChange} label="Enter Number / User Id"/> */}
            <CustomInput max={10} name="userId" value={values.userId.value} handleChange={handleChange} placeholder={"Enter Phone Number"} />
            {errors.userId && <p className="absolute errorText">{errors.userId}</p>}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Amount<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomInput max={7} name="amount" value={values.amount.value} handleChange={handleInputChange} placeholder={"Amount"} />
            {errors.amount && <p className="absolute errorText">{errors.amount}</p>}
          </div>
          {/* <label className="valueTransfer-label my-3 pt-2">Type<span className="text-font-red text-sm font-bold">*</span></label>
             <div>
             <CustomInput name="type"  value={values.type.value} handleChange={handleChange} placeholder={"Type"}/>
             {errors.type && <p className="absolute errorText">{errors.type}</p>}
             </div> */}
          {/* <label className="valueTransfer-label my-3 pt-2">Remarks<span className="text-font-red text-sm font-bold">*</span></label>
             <div>
              <CustomInput name="remarks"  value={values.remarks.value} handleChange={handleChange} placeholder={"Remarks"}/>
              {errors.remarks && <p className="absolute errorText">{errors.remarks}</p>}
              </div> */}
          <div className="flex justify-between  items-center mt-8">
            <label className="valueTransfer-label"></label>
            <CustomButton style={buttonStyle} label={"Submit"} />
          </div>
        </div>
      </form>
      <CustomModal data={sendData} buttonLabel={"Submit"} name={"transferByNumber"} header={"Enter T-PIN"} modalType="transferByNumber" />
    </Grid>
  );
};

export default TransferByNumber;
