import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from 'react';
import CustomButton from "../../../components/common/customButton/customButton";
import CustomDatePicker from "../../../components/common/customDatePicker/customDatePicker";
import CustomSelect from '../../../components/common/customSelect/customSelect';
import UseForm from "../../../hooks/useForm";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { TransferRetailervalidation } from "../../../validation/validation";
import "./myearningsretailer.css";

const buttonStyle = {
  width: "35%",
  lineHeight: "17px",
  letterSpacing: "4px",
};

const MyEarningsRetailer = ({ setData, selectData }) => {

  const [state, contextDispatch] = useContext(Context);

  const initialState = {
    startDate: { value: "", required: true },
    endDate: { value: "", required: true },
    retailer: { value: "", required: true }
  };

  function submitForm(value) {
    var today = new Date(values.endDate.value);
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    
    setData({ startDate: values.startDate.value, endDate: endDate, userId: values?.retailer?.value?.value });
    contextDispatch({ type: Constants.CALL_API, payload: !state[0]?.callApi });
  }


  const { values, handleSubmit, errors, dateChange, selectChange, clearState } = UseForm(
    submitForm,
    TransferRetailervalidation,
    initialState
  );

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        md={12}
        container
        columnSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid md={4}>
          <div className="flex justify-center flex-col my-5">
            <CustomDatePicker
              dateChange={dateChange}
              name="startDate"
              selected={values.startDate.value}
              placeholder={"Select Start Date"}
            />
            {errors.startDate && (
              <p className="absolute mt-16 errorText">{errors.startDate}</p>
            )}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="flex justify-center flex-col my-5">
            <CustomDatePicker
              minDate={values.startDate.value}
              dateChange={dateChange}
              name="endDate"
              selected={values.endDate.value}
              placeholder={"Select End Date"}
            />
            {errors.endDate && (
              <p className="absolute mt-16 errorText">{errors.endDate}</p>
            )}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="flex justify-center flex-col my-5">
            <CustomSelect
              data={selectData}
              name="retailer"
              value={values.retailer.value}
              handleChange={selectChange}
              label='Select a Retailer'
            />
            {errors.retailer && <p className="absolute mt-16 errorText">{errors.retailer}</p>}
          </div>
        </Grid>
        <div className="earningsretailer-btn">
          <CustomButton style={buttonStyle} label={"Get My Earnings Retailer"} />
        </div>
      </Grid>
    </form>
  );
};

export default MyEarningsRetailer;
