export const Constants = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',

  SHOW_POPUP: 'SHOW_POPUP',

  // SHOW_OTP_FORM: 'SHOW_OTP_FORM',
  // SHOW_LOGIN_FORM: 'SHOW_LOGIN_FORM',
  // SHOW_FORGOT_PASSWORD_FORM: 'SHOW_FORGOT_PASSWORD_FORM',
  // FORGOT_OTP_VERIFY_FORM: 'FORGOT_OTP_VERIFY_FORM',
  // SET_PASSWORD_FORM: 'SET_PASSWORD_FORM',

  HANDLE_LOGIN: 'HANDLE_LOGIN',

  UPDATE_USERID:"UPDATE_USERID",

  ACTIVE_TAB_ID: 'ACTIVE_TAB_ID',

  CUSTOM_MODAL: 'CUSTOM_MODAL',

  CALL_API: 'CALL_API',

  CALL_BALANCE_API: 'CALL_BALANCE_API',
};
