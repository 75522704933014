import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomTab from '../../../components/common/customTab/customTab';
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from '../../../hooks/dateFormatter';
import { MyLedgerList } from "../../../hooks/useTableData";
import distributorService from '../../../services/distributor_service';
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import Ledger from "./ledger";
import "./myledgermodal.css";

const values = [
  { id: 1, text: "My Ledger" },
];

const tableheadMyLedger = [
  {
    name: "Transaction date",
    selector: (row) => row.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row.createdAt, "table"),
  },
  {
    name: "Transaction Type",
    selector: (row) => row.type,
    sortable: true,
    id: "type",
    format: (row) => row?.type?.toUpperCase(),
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "amount",
    // width:'180px !important'
  },
  {
    name: "Reference Type",
    selector: (row) => row.referenceType,
    sortable: true,
    id: "referenceType",
    // width:'180px !important'
  },
];

const MyLedgerModal = () => {
  const [fetchedTableData, setFetchedTableData] = useState("");
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState(null);

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

  const dateObj = { startDate: startDate, endDate: today };

  const handleTabSelected = (val) => {
    contextDispatch({ type: Constants.ACTIVE_TAB_ID, payload: val });
  };

  const fetchDataFromApi = (data) => {
    setFetchedTableData(data);
  };

  useEffect(() => {
    setPending(true);
    distributorService
      .My_Ledger({
        startDate: DateFormatter(new Date(startDate), 'payload'),
        endDate: DateFormatter(new Date(endDate), 'payload'),
      })
      .then((res) => {
        setPending(false);
        setFetchedTableData(res?.data?.data);
      }).catch((err) => setPending(false));
  }, []);

  const { myLedgerListdata, myLedgerListhead } = MyLedgerList(
    fetchedTableData,
    tableheadMyLedger
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1}></Grid>
        <Grid item sm={10}>
          <div className="myledger-modal-container modal-transparency">
            <CustomTab values={values} selected={handleTabSelected} />
            <Ledger fetchDataFromApi={fetchDataFromApi} dateObj={dateObj} setPending={setPending} />
          </div>
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>
      <div>
        <div className="mt-16 bg-white p-5 rounded-xl relative table-container">
          <CustomTable
            pending={pending}
            tablehead={myLedgerListhead}
            tabledata={myLedgerListdata}
          />
        </div>
      </div>
    </>
  );
};

export default MyLedgerModal;
