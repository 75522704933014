import { useContext, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomCheckBox from '../../../components/common/customCheckBox/customCheckBox';
import CustomDatePicker from "../../../components/common/customDatePicker/customDatePicker";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomSelect from '../../../components/common/customSelect/customSelect';
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from "../../../hooks/useForm";
import distributorService from '../../../services/distributor_service';
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { RetailerDetailsvalidation } from "../../../validation/validation";
import "./createreatilerform.css";
import {  TextField } from "@mui/material";

const selectData = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

function CreateReatilerForm({ panNum, setView }) {
  const buttonStyle = {
    width: "25%",
    paddingLeft: "32px",
    paddingRight: "32px",
    lineHeight: "17px",
    letterSpacing: "4px",
  };

  const selectStyle = {
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    height: "42px",
    width: "100%",
  };

  const initialState = {
    // kits: { value: "", required: true },
    firstName: { value: "", required: true },
    lastName: { value: "", required: true },
    mobile: { value: "", required: true, regexp: /^[0-9_ ]*$/ },
    PAN: { value: panNum },
    adhar: { value: "", required: true, regexp: /^[0-9_ ]*$/ },
    dob: { value: "", required: true },
    email: { value: "", required: true,email: true, emailMessage: 'Email address is not valid!' },
    address: { value: "", required: true },
    shopName: { value: "", required: true },
    gender: { value: "", required: true },
  };

  const [state, contextDispatch] = useContext(Context);
  const [checked, setChecked] = useState(false);

  const { handleChange, values, handleSubmit, errors, dateChange, selectChange } = UseForm(submitForm, RetailerDetailsvalidation, initialState);

  function submitForm(value) {
    distributorService
      .Create_Retailer(data)
      .then((res) => {
        setView('createRetailer');
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: { isOpen: true, severity: "success", message: res?.data?.message },
        });
      })
      .catch((err) => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: { isOpen: true, severity: "error", message: err?.response?.data?.message }
        });
      });
  }

  const data = JSON.stringify({
    firstName: values.firstName.value,
    lastName: values.lastName.value,
    mobile: values.mobile.value,
    roles: ["RETAILER"],
    email: values.email.value,
    address: values.address.value,
    pan: panNum,
    aadhaar: values.adhar.value,
    dob: DateFormatter(new Date(values.dob.value), 'payload'),
    // virtualaccount: checked,
    virtualaccount: false,
    gender: values?.gender?.value?.value,
    shopName: values.shopName.value
  });

  const handleChangeCheckBox = (e) => {
    setChecked((prev) => !prev);
  }

  return (
    <div id="createRetailer-form-container" className="bg-white relative">
      <form onSubmit={handleSubmit} autoComplete="off">
        <p id="createRetailer-form-title">Retailer Details</p>
        <div className="flex justify-between gap-x-20">
          <div className="createRetailer-form">
            <div>
              <label className="w-3/12">First Name</label>
              <div className="w-2/3">
                <CustomInput name="firstName" value={values.firstName.value} handleChange={handleChange} placeholder={"Enter First Name"} />
                {errors.firstName && <p className="absolute errorText">{errors.firstName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Last Name</label>
              <div className="w-2/3">
                <CustomInput name="lastName" value={values.lastName.value} handleChange={handleChange} placeholder={"Enter Last Name"} />
                {errors.lastName && <p className="absolute errorText">{errors.lastName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Mobile</label>
              <div className="w-2/3">
                <CustomInput min={10} max={10} name="mobile" value={values.mobile.value} handleChange={handleChange} variant={"mobile"} placeholder={"Enter  Number"} />
                {errors.mobile && <p className="absolute errorText">{errors.mobile}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Aadhaar</label>
              <div className="w-2/3">
                <CustomInput min={12} max={12} name="adhar" value={values.adhar.value} handleChange={handleChange} placeholder={"Enter Aadhaar Number"} />
                {errors.adhar && <p className="absolute errorText">{errors.adhar}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Gender</label>
              <div className="w-2/3">
                <CustomSelect
                  style={selectStyle}
                  data={selectData}
                  name="gender"
                  value={values.gender.value}
                  handleChange={selectChange}
                  label="Select Gender"
                />
                {errors.gender && <p className="absolute errorText">{errors.gender}</p>}
              </div>
            </div>
          </div>
          <div className="createRetailer-form">
            <div>
              <label className="w-3/12">Shop Name</label>
              <div className="w-2/3">
                <CustomInput name="shopName" value={values?.shopName?.value} handleChange={handleChange} placeholder={"Enter Shop Name"} />
                {errors.shopName && <p className="absolute errorText">{errors.shopName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">PAN</label>
              <div className="w-2/3">
                <CustomInput disabled={true} name="PAN" value={panNum} handleChange={handleChange} placeholder={"Enter PAN Number"} />
                {errors.PAN && <p className="absolute errorText">{errors.PAN}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Date of Birth</label>
              <div className="w-2/3">
                <CustomDatePicker name="dob" dateChange={dateChange} placeholder={"Select A Date"} selected={values.dob.value} />
                {errors.dob && <p className="absolute errorText">{errors.dob}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Email ID</label>
              <div className="w-2/3">
                <CustomInput name="email" value={values.email.value} handleChange={handleChange} placeholder={"Enter E-mail ID"} />
                {errors.email && <p className="absolute errorText">{errors.email}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Address</label>
              <div className="w-2/3">
              <TextField
                placeholder='Enter Address'
                variant="outlined"
                inputProps={{ maxLength:100 }}
                multiline={true}
                minRows={5}
                name="address"
                onChange={handleChange}
                value={values.address.value}
                sx={{
                  borderRadius: "8px",
                  minHeight: "200px",
                  width: "100%",
                  padding: "30px",
                  background:
                    "linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%)",
                  boxShadow: " 0px 1px 2px rgb(0 0 0 / 25%)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  '& ::placeholder': {
                    textOverflow: 'ellipsis !important',
                    opacity:1,
                   color: '#8392AB',
                   fontFamily: 'Libre Franklin, sans-serif',
                   fontWeight: 400,
                   fontSize: '1rem',
                   lineHeight: '1.4375rem',
                   letterSpacing: '0.00938rem',
                 }
                }}
              />
                {errors.address && <p className="absolute errorText">{errors.address}</p>}
              </div>
            </div>
            {/* <div>
              <div className='w-1/2'>
                <label className="w-3/12">Create Virtual Account</label>
                <CustomCheckBox checked={checked} handleChange={handleChangeCheckBox} />
              </div>
            </div> */}
          </div>
        </div>
        <div className="createRetailer-form-submitbtn">
          <CustomButton style={buttonStyle} label={"Create Retailer"} />
        </div>
      </form>
    </div>
  );
}

export default CreateReatilerForm;
