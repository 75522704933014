import "./index.css";
import DashboardCard from "./dashboardCard/dashboardCard";

const Dashboard = () => {
  return (
    <div className="bg-body-dashboard">
      <DashboardCard />
    </div>
  );
};

export default Dashboard;
