import { useCallback, useState } from 'react';

const UseForm = (callback, ValidateInfo, initialState) => {
  const [values, setValues] = useState(initialState);

  const [errors, setErrors] = useState({});

  const setDataAndErrors = useCallback(
    (data) => {
      setValues(data);

      const errors = ValidateInfo(data);

      setErrors(errors);
    },
    [ValidateInfo]
  );

  const handleChange = useCallback(
    (e) => {
      let updatedData;
      if (values[e.target.name]?.regexp) {
        updatedData = {
          ...values,
          [e.target.name]: {
            ...values[e.target.name],
            value: values[e.target.name]?.regexp.test(e.target.value) ? e.target.value : values[e.target.name].value,
            touched: true,
          },
        };
      } else {
        updatedData = {
          ...values,
          [e.target.name]: {
            ...values[e.target.name],
            value: e.target.value,
            touched: true,
          },
        };
      }
      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  const dateChange = useCallback(
    (item) => {
      let updatedData;
      updatedData = {
        ...values,
        [item.name]: {
          ...values[item.name],
          value: item.value?.$d?.toString(),
          touched: true,
        },
      };
      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  const selectChange = useCallback(
    (item) => {
      let updatedData;
      updatedData = {
        ...values,
        [item.name]: { ...values[item.name], value: item.value },
      };

      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = ValidateInfo(values, true);

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      callback(true);
    }
  };

  const clearState = (data) => {
    setValues(data);
    setErrors({});
  };

  const resetKey = (data, key) => {
    setValues((prev) => ({ ...prev, [key]: data }));
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    dateChange,
    selectChange,
    clearState,
    resetKey,
  };
};

export default UseForm;
