
import ValueTransferModal from "./valueTransferModal/valueTransferModal";

const ValueTransfer = () => {

  return (
    <div className="m-5">
      <div className="relative bottom-20">
        <ValueTransferModal />
      </div>
    </div>
  );
};

export default ValueTransfer;
