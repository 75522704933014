import ListDistributorModal from './listDistributorModal';
function YourChain() {
  return (
    <div className="m-5">
      <div className="relative bottom-20">
        <ListDistributorModal />
      </div>
    </div>
  );
}

export default YourChain;
