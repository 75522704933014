import { useState } from "react";
import CreateReatilerForm from "./createReatilerForm";
import CreateRetailer from "./createRetailer";
import "./createretailermodal.css";

function CreateRetailerModal() {
  const [panNum, setPanNum] = useState("");
  const [view, setView] = useState("createRetailer");

  const handlePanNumber = (e) => setPanNum(e);

  const ENUM_STATES = {
    createRetailer: <CreateRetailer handlePanNumber={handlePanNumber} setView={setView} />,
    createRetailerForm: <CreateReatilerForm panNum={panNum} setView={setView} />
  }

  return (
    <>
      {ENUM_STATES[view]}
    </>
  );
}

export default CreateRetailerModal;
