import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomSelect from "../../../components/common/customSelect/customSelect";
import CustomTable from "../../../components/common/customTable/customTable";
import UseForm from "../../../hooks/useForm";
import { BuyKitsIssuedList } from "../../../hooks/useTableData";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { Kitmgntvalidation } from "../../../validation/validation";
import "./transferkitsmodal.css";

const buttonStyle = {
  width: "15%",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const tableHeadBuyKits = [
  {
    name: "Distibutor",
    selector: (row) => row.firstName,
    sortable: true,
    id: "firstName",

  },
  {
    name: "Kits Issued",
    selector: (row) => row.count,
    sortable: true,
    id: "count",

  },
  {
    name: "Remarks",
    selector: (row) => row.remark,
    sortable: true,
    id: "remark",

  },
];

const tableStyle = {
  color: "text-bluebutton",
};

const initialState = {
  distributor: { value: "", required: true },
  kitsTransfer: {
    value: "",
    required: true,
    regexp: /^[1-9][0-9]*$/,
    minLength: 1,
    minLengthMessage: 'At least 2 characters long!',
    maxLength: 2,
    maxLengthMessage: 'Too many characters!'
  },
  remarks: { value: "", required: true },
};

const KitManagementModal = () => {
  const [fetchedData, setFetchedData] = useState("");
  const [fetchedTableData, setFetchedTableData] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pending, setPending] = useState(true);
  const [KitBalance, setKitBalance] = useState(0);
  const [state, contextDispatch] = useContext(Context);

  function submitForm(value) {
    setIsSubmitting(value);
    setOpen(true);
    if (value) {
      distributorService
        .Transfer_Kits({
          receiverId: values?.distributor?.value?.value,
          kitCount: values?.kitsTransfer?.value,
          remark: values?.remarks?.value,
        })
        .then((data) => {
          contextDispatch({ type: Constants.CALL_API, payload: !state[0]?.callApi });
          contextDispatch({
            type: Constants.SHOW_POPUP,
            payload: {
              showLotti: true,
              lottiSeverity: "success",
            },
          });
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "success",
              message: data?.data?.message,
            },
          });
        })
        .catch((err) => {
          contextDispatch({ type: Constants.CALL_API, payload: !state[0]?.callApi });
          contextDispatch({
            type: Constants.SHOW_POPUP,
            payload: {
              showLotti: true,
              lottiSeverity: "fail",
            },
          });
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "error",
              message: err?.response?.data?.message,
            },
          });
        });
    }
  }

  const { issuedListdata, issuedListhead } = BuyKitsIssuedList(
    fetchedTableData,
    tableHeadBuyKits
  );

  useEffect(() => {
    setPending(false);
    distributorService
      .Kit_Balance()
      .then((res) => setKitBalance(res?.data?.data))
    distributorService
      .Distributor_View_Child()
      .then((res) => setFetchedData(res.data.data))
    distributorService
      .Buy_Kits_Issued()
      .then((res) => setFetchedTableData(res.data.data[0]))
    return () => {
      setPending(true);
    };
  }, [state[0]?.callApi]);

  const selectData = [];

  fetchedData &&
    fetchedData.map((e) => {
      selectData.push({
        value: `${e.id}`,
        label: `${e.firstName} ${e.lastName}`,
      });
    });

  const {
    handleChange,
    selectChange,
    values,
    handleSubmit,
    errors,
    resetKey
  } = UseForm(submitForm, Kitmgntvalidation, initialState);

  const handleInputChange = (e) => {
    const pattern = /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    }
    else if (e.target.value === "") {
      resetKey(initialState.kitsTransfer, "kitsTransfer");
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1}></Grid>
        <Grid item sm={10}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="transferkits-modal modal-transparency">
              <p className="text-bluebutton transferkits-title">
                {`Kits Available ${KitBalance}`}
              </p>
              <div className="flex gap-5 mt-5 ">
                <div className="flex flex-col w-full">
                  <p className="mb-4 text-bluebutton transferkits-title">
                    Select Distributor
                  </p>
                  <div className="relative">
                    <CustomSelect
                      data={selectData}
                      name="distributor"
                      value={values.distributor.value}
                      handleChange={selectChange}
                      label={"Select Distributor"}
                    />
                    {errors.distributor && (
                      <p className="absolute errorText">{errors.distributor}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <p className="mb-4 text-bluebutton transferkits-title">
                    Kits To Transfer
                  </p>
                  <div className="relative">
                    <CustomInput
                      max={2}
                      name="kitsTransfer"
                      value={values.kitsTransfer.value}
                      handleChange={handleInputChange}
                      placeholder={"Enter No. Of Kits"}
                    />
                    {errors.kitsTransfer && (
                      <p className="absolute errorText">
                        {errors.kitsTransfer}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <p className="mb-4 text-bluebutton transferkits-title">
                    Remarks
                  </p>
                  <div className="relative">
                    <CustomInput
                      name="remarks"
                      value={values.remarks.value}
                      handleChange={handleChange}
                      placeholder={"Remarks"}
                    />
                    {errors.remarks && (
                      <p className="absolute errorText">{errors.remarks}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="transferkits-btn">
                <CustomButton style={buttonStyle} label={"Submit"} />
              </div>
            </div>
          </form>
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>

      <div className="transferkits-table-modal mt-16 text-bluebutton">
        <p className="transferkits-table-title mb-5 mx-1">
          Recent Kits Issuesd BY Me
        </p>
        <div className="table-container">
          <CustomTable
            pending={pending}
            style={tableStyle}
            tabledata={issuedListdata}
            tablehead={issuedListhead}
          />
        </div>
      </div>
      {/* <div className="kitmanagement-table-modal mt-6 text-bluebutton">
        <p className="kitmanagement-table-title mb-5 mx-1">
          Recent Kits Issuesd BY Me
        </p>
        <div>
          <CustomTable
            style={tableStyle}
            tabledata={tabledata2}
            tablehead={tablehead2}
          />
        </div>
      </div> */}
    </>
  );
};

export default KitManagementModal;
