import { Grid } from '@mui/material';
import { useCallback, useContext, useState } from "react";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomTab from '../../../components/common/customTab/customTab';
import distributorService from '../../../services/distributor_service';
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { PANvalidation } from "../../../validation/validation";
import "./createretailer.css";

const tabValues = [
  { id: 1, text: "Create Retailer" },
];

const initialState = {
  PAN: {
    value: "",
    pan: true,
    requiredMessage: "PAN card number is required!",
    panMessage: "PAN card number  not valid",
    required: true,
  },
};

function CreateRetailer({ handlePanNumber, setView }) {

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [state, contextDispatch] = useContext(Context);

  const setDataAndErrors = useCallback(
    (data) => {
      setValues(data);

      const errors = PANvalidation(data);
      if (!errors["PAN"]) {
        handlePanNumber(data?.PAN?.value);
      }

      setErrors(errors);
    },
    [PANvalidation]
  );

  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      let updatedData;
      const pattern = /^[a-zA-Z0-9]*$/;
      updatedData = pattern.test(e.target.value)
        ? {
          ...values,
          [e.target.name]: {
            ...values[e.target.name],
            value: e.target.value.toUpperCase().slice(0, 10),
            touched: true,
          },
        }
        : { ...values };
      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  if (values.PAN.value.length === 10) {
    distributorService
      .Pan_Validation({ pan: values.PAN.value })
      .then((res) => {
        setView('createRetailerForm');
      })
  }

  const handleTabSelected = (val) => {
    contextDispatch({
      type: Constants.ACTIVE_TAB_ID,
      payload: val,
    });
  };

  return (

    <Grid container spacing={2}>
      <Grid item sm={3}></Grid>
      <Grid item sm={6}>
        <div className="modal-transparency" id="createRetailer-yourchain-modal-container">
          <CustomTab values={tabValues} selected={handleTabSelected} />
          <div className="bg-white mt-10 createRetailer-yourchain-modal-customtable-container">
            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <span className="relative w-full">
                <CustomInput value={values?.PAN?.value} handleChange={handleChange} name="PAN" placeholder="Enter the PAN Number" />
                {errors.PAN && <p className="absolute errorText">{errors.PAN}</p>}
              </span>
            </form>

            <p id="createRetailer-checking" className={values?.PAN.value ? "none" : "hidden"}>
              Checking
              <span className="createRetailer-bouncing-loader">
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
          </div>
        </div>
      </Grid>
      <Grid item sm={3}></Grid>
    </Grid>
  );
}

export default CreateRetailer;
