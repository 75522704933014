import CircularProgress from '@mui/material/CircularProgress';
import "./loader.css"

const Loader = () => {
  return (

<div className="lds-spinner">
  Loading...............
</div>

  )
}

export default Loader