import { Navigate } from "react-router-dom";
import TokenService from '../services/token_service';

const ProtectedRoute=({children})=>{
    const expectedRoles=[TokenService?.getUser()?.userType];
    const isAuthorized=true
    const areRolesRequired=!!expectedRoles.length
    const roles=["distributor","super_distributor"];

    const rolesMatch=areRolesRequired?expectedRoles?.some(r=>roles.indexOf(r)>=0):true
    if(!isAuthorized|| !rolesMatch){
        return <Navigate to="/" replace />
    }
 
    return children
}

export default ProtectedRoute;