import { BrowserRouter } from 'react-router-dom';
import './App.css';
import ModalProvider from './components/modalProvider/modalProvider';
import RouteRender from './routes/route-render';
import { ContextProvider } from './stateManagement/context';

function App() {
  return (
    <>
      <BrowserRouter>
        <ContextProvider>
          <ModalProvider>
            <RouteRender />
          </ModalProvider>
        </ContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
