import { ReactComponent as Dashboard } from "../assets/svg/nav/dashboard.svg";
import { ReactComponent as FundLoad } from "../assets/svg/nav/fundLoad.svg";
import { ReactComponent as KitManagement } from "../assets/svg/nav/kitManagement.svg";
import { ReactComponent as MyLedger } from "../assets/svg/nav/myLedger.svg";
import { ReactComponent as Payments } from "../assets/svg/nav/payments.svg";
import { ReactComponent as Tpin } from "../assets/svg/nav/tPin.svg";
import { ReactComponent as Transaction } from "../assets/svg/nav/transactions.svg";
import { ReactComponent as ValueTransfer } from "../assets/svg/nav/valueTransfer.svg";
import { ReactComponent as YourChain } from "../assets/svg/nav/yourChain.svg";

const appRoutes = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  PAYMENTS: "/payments",
  VIRTUAL_ACCOUNT: "/get-funds-by-virtual-account",
  VALUE_TRANSFER: "/request-value",
  VALUE_TRANSFER_REPORT: "/value-transfer-report",
  FUND_LOAD: "/fund-load",
  RETAILER_LIST: "/retailer-list",
  CREATE_RETAILER: "/create-retailer",
  DISTRIBUTOR_LIST: "/distributor-list",
  CREATE_DISTRIBUTOR: "/create-distributor",
  TRANSACTION: "/transactions",
  TPIN: "/t-pin",
  MY_LEDGER: "/my-ledger",
  MY_EARNINGS: "my-earnings",
  BUY_KITS: "/buy-kits",
  TRANSFER_KITS: "/transfer-kits",
  UNKNOWN: "/*",
};

const AllRoutesData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Dashboard />,
    cName: "active",
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
    navId: 1,
  },
  {
    title: "Payments",
    path: "/payments",
    icon: <Payments />,
    cName: "active",
    navId: 2,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_dt", "admin_dt_rt"],
    subNav: [
      {
        title: "Get funds by Payment Gateway",
        path: "/payments",
        icon: "",
        cName: "active",
        roles: ["admin_sd_dt", "admin_sd", "admin_dt"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
      // {
      //   title: "Get funds by Virtual Account",
      //   path: "/get-funds-by-virtual-account",
      //   icon: "",
      //   cName: "active",
      //   roles: ["distributor", "super_distributor"],
      //   chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      // },
    ],
  },
  {
    title: "Request Value",
    path: "/request-value",
    icon: <ValueTransfer />,
    cName: "active",
    navId: 3,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
    subNav: [
      {
        title: "Request Value",
        path: "/request-value",
        icon: "",
        cName: "active",
        roles: ["distributor", "super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
      {
        title: "Value Transfer Report",
        path: "/value-transfer-report",
        icon: "",
        cName: "active",
        roles: ["distributor", "super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
    ],
  },
  {
    title: "Fund Load",
    path: "/fund-load",
    icon: <FundLoad />,
    cName: "active",
    navId: 4,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
  },
  {
    title: "Retailer Management",
    path: "/retailer-list",
    icon: <YourChain />,
    cName: "active",
    navId: 5,
    roles: ["distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
    subNav: [
      {
        title: "Retailer List",
        path: "/retailer-list",
        icon: "",
        cName: "active",
        roles: ["distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
      {
        title: "Create Retailer ",
        path: "/create-retailer",
        icon: "",
        cName: "active",
        roles: ["distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
    ],
  },
  {
    title: "Distributor Management",
    path: "/distributor-list",
    icon: <YourChain />,
    cName: "active",
    navId: 6,
    roles: ["super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
    subNav: [
      {
        title: "Distributor List",
        path: "/distributor-list",
        icon: "",
        cName: "active",
        roles: ["super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
      {
        title: "Create Distributor ",
        path: "/create-distributor",
        icon: "",
        cName: "active",
        roles: ["super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
    ],
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: <Transaction />,
    cName: "active",
    navId: 7,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
  },
  {
    title: "T-PIN",
    path: "/t-pin",
    icon: <Tpin />,
    cName: "active",
    navId: 8,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
  },
  {
    title: "Report",
    path: "/my-ledger",
    icon: <YourChain />,
    cName: "active",
    navId: 9,
    roles: ["distributor", "super_distributor"],
    chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
    subNav: [
      {
        title: "My Ledger",
        path: "/my-ledger",
        icon: "",
        cName: "active",
        roles: ["distributor", "super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
      {
        title: "My Earnings",
        path: "/my-earnings",
        icon: "",
        cName: "active",
        roles: ["distributor", "super_distributor"],
        chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
      },
    ],
  },
  {
    title: "Kit Management",
    path: "/buy-kits",
    icon: <KitManagement />,
    cName: "active",
    navId: 10,
    roles: ["super_distributor", "distributor"],
    chainType: ["admin_sd", "admin_dt"],
    subNav: [
      {
        title: "Buy Kits",
        path: "/buy-kits",
        icon: "",
        cName: "active",
        roles: ["super_distributor", "distributor"],
        chainType: ["admin_sd", "admin_dt"],
      },
      {
        title: "Transfer Kits",
        path: "/transfer-kits",
        icon: "",
        cName: "active",
        roles: ["super_distributor", "distributor"],
        chainType: ["admin_sd"],
      },
    ],
  },
];

// let Sidebar_Data = [];

// if (localStorage.getItem("user")) {
//   const user = JSON.parse(localStorage.getItem("user"));
//   console.log("user", user);
//   Sidebar_Data = AllRoutesData.filter((item) => {
//     if (user?.userType === "distributor" && item?.chainType.includes(user?.chainType)) {
//       return item;
//     }
//     else if (user?.userType === "super_distributor") {
//       console.log("first");
//       return item;
//     }
//   });
// }

export { appRoutes, AllRoutesData };
