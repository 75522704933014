import CustomButton from "../../components/common/customButton/customButton";
import "./pagenotfound.css";
import {ReactComponent as PagenotfoundSvg} from "../../../src/assets/svg/pagenotfound/pagenotfound.svg";
import { useNavigate } from "react-router-dom";

const buttonHome = {
    width:"100%",
    lineHeight: "17px",
    letterSpacing:"4px",
    fontSize:"14px",
    fontWeight:800,
    backgroundColor:"white",
    borderRadius:"20px",
    height:"40px",
    border:"3px solid #112467"
  }

  const buttonGoBack = {
    width:"100%",
    lineHeight: "17px",
    letterSpacing:"4px",
    fontSize:"14px",
    fontWeight:800,
    color:"white",
    backgroundColor:"#112467",
    borderRadius:"20px",
    height:"40px",

  }

const PageNotFound = () => {

    const navigate = useNavigate();

  return (
    <div className="pagenotfound-container">
      <div className="pagenotfound-content">
        <h1>Oops!</h1>
        <p>
          The page you requested was not found. Try going back to previous page
          or home{" "}
        </p>
        <div className="pagenotfound-btncontiner">
        <span className="btn-goback"><CustomButton handleClick={() =>navigate(window.history.back())} style={buttonGoBack} label={"Go Back"} variant={"404"}/></span>
        <span className="btn-home"><CustomButton handleClick={() =>navigate("/dashboard")} style={buttonHome} variant={"404"} label="Home"/></span>
        </div>
      </div>
      <div className="pagenotfound-image">
        <PagenotfoundSvg />
      </div>
    </div>
  );
};

export default PageNotFound;
