import "./custominput.css";

const CustomInput = ({ placeholder, style, name, value, handleChange, variant, max, disabled, min,type }) => {

    switch (variant) {
        case "mobile":
            return (<div className="flex items-center">
                <span id="validation-span-mobile">+91</span>
                <input disabled={disabled} minLength={min} maxLength={max} className="input-background" type={"text"} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder} id="validation-input-mobile" />
            </div>);
        case "symbol":
            return (<div className="validation-container-symbol pl-5">
                <span id="validation-span-symbol">₹</span>
                <input disabled={disabled} minLength={min} maxLength={max} className="input-background" type={"text"} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder} id="validation-input-symbol" />
            </div>);
        default:
            return (
                <input disabled={disabled} minLength={min} maxLength={max} className="py-2 my-2 px-6 w-full input-background validation-input" type={type} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder}  />
            );

    }
};

export default CustomInput;