import FundLoadModal from "../fundLoad/fundLoadModal/fundLoadModal";
function FundLoad() {
    return(
        <div className="m-5">
            <div className="relative bottom-32">
            <FundLoadModal />
            </div>
        </div>
    )
}

export default FundLoad;