import PaymentsVirtualAccountModal from "./PaymentsVirtualAccountModal/PaymentsVirtualAccountModal";

function PaymentsVirtualAccount() {
    return(
        <div className="m-5">
            <div className="relative bottom-20">
            <PaymentsVirtualAccountModal />
            </div>
        </div>
    )
}

export default PaymentsVirtualAccount;