const FundLoadRequestList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id && dataproperty !== 'status') {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'status') {
            if (tdata['status'] === 'pending') {
              if (tdata['wfp']) {
                tempObj = { ...tempObj, ['status']: 'WFP' };
              } else {
                tempObj = { ...tempObj, ['status']: 'Pending' };
              }
            } else {
              tempObj = { ...tempObj, ['status']: tdata['status'] };
            }
          }
          if (dataproperty === 'user') {
            tempObj = {
              ...tempObj,
              name: tdata[dataproperty]?.firstName + ' ' + tdata[dataproperty]?.lastName,
              edgeId: tdata[dataproperty]?.edgeId,
            };
          }
        });
      }
      tabledata.push({
        ...tempObj,
        id: tdata.id,
      });
    });
  let requestData = tabledata;
  let requestHead = table_Head;
  return { requestData, requestHead };
};

const FundLoadCompleted = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'user') {
            tempObj = {
              ...tempObj,
              name: tdata[dataproperty]?.firstName + ' ' + tdata[dataproperty]?.lastName,
              edgeId: tdata[dataproperty]?.edgeId,
            };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  let completedData = tabledata;
  let completedHead = table_Head;
  return { completedData, completedHead };
};

const RetailerList = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
        }
      });
      tabledata.push(tempObj);
    });
  let tablehead = table_Head;
  return { tabledata, tablehead };
};

const TransactionListRecharge = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
        }
      });
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  let tableheadRecharge = table_Head;
  let tabledataRecharge = tabledata;
  return { tabledataRecharge, tableheadRecharge };
};

const TransactionListDMT = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
        }
      });
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  let tableheadDMT = table_Head;
  let tabledataDMT = tabledata;
  return { tabledataDMT, tableheadDMT };
};

const DistributorList = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
        }
      });
      tabledata.push(tempObj);
    });
  let tablehead = table_Head;
  return { tabledata, tablehead };
};

const ValueTransferList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id && dataproperty !== 'status') {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'status') {
            if (tdata['status'] === 'pending') {
              if (tdata['wfp']) {
                tempObj = { ...tempObj, ['status']: 'WFP' };
              } else {
                tempObj = { ...tempObj, ['status']: 'Pending' };
              }
            } else {
              tempObj = { ...tempObj, ['status']: tdata['status'] };
            }
          }
          if (dataproperty === 'user') {
            tempObj = {
              ...tempObj,
              name: tdata[dataproperty]?.firstName + ' ' + tdata[dataproperty]?.lastName,
              edgeId: tdata[dataproperty]?.edgeId,
            };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });

  return { tabledata, table_Head };
};

const PaymentGatewayList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  const ProvidersType = {
    pg_cashfree: 'PG Cashfree',
    pg_razorpay: 'PG Razorpay',
  };
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'provider') {
            tempObj = { ...tempObj, [dataproperty]: ProvidersType[tdata[dataproperty]] };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });

  return { tabledata, table_Head };
};

const BuyKitsIssuedList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  let issuedListdata = tabledata;
  let issuedListhead = table_Head;
  return { issuedListdata, issuedListhead };
};

const BuyKitsList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1, amount: 100 });
    });
  let kitsListdata = tabledata;
  let kitsListhead = table_Head;
  return { kitsListdata, kitsListhead };
};

const MyLedgerList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];

  const referenceType = {
    fund_transfer: 'Fund Transfer',
    recharge: 'Recharge',
    ecaps_dmt: 'ECAPS DMT',
    credit_card_payment: 'Credit Card Payment',
    kit_purchase: 'Kit Purchase',
    virtual_account: 'Virtual Account',
    pg_paymentlink: 'Pg Paymentlink',
    recharge_commission: 'Recharge Commission',
    credit_card_commission: 'Credit Card Commission',
    ecaps_dmt_commission: 'ECAPS DMT Commission',
  };

  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'referenceType' && referenceType.hasOwnProperty(tdata[dataproperty])) {
            tempObj = { ...tempObj, referenceType: referenceType[tdata[dataproperty]] };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  let myLedgerListdata = tabledata;
  let myLedgerListhead = table_Head;
  return { myLedgerListdata, myLedgerListhead };
};

const MyEarningsList = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  const user = JSON.parse(localStorage.getItem('user'));

  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
          if (user?.userType === 'distributor') {
            tempObj = { ...tempObj, commission: e['distributorCommission'] };
          }
          if (user?.userType === 'super_distributor') {
            tempObj = { ...tempObj, commission: e['superDistributorCommission'] };
          }
        }
      });
      tabledata.push(tempObj);
    });
  let myEarningsListhead = table_Head;
  let myEarningsListdata = tabledata;
  return { myEarningsListhead, myEarningsListdata };
};

const MyEarningsRetailerList = (table_Data, table_Head) => {
  const tabledata = [];
  let tempObj = {};
  const user = JSON.parse(localStorage.getItem('user'));

  table_Data &&
    table_Data?.map((e, i) => {
      table_Head.map((headrow, i) => {
        for (const property in e) {
          if (property === headrow.id) {
            tempObj = { ...tempObj, [property]: e[property] };
          }
        }
      });
      tabledata.push(tempObj);
    });
  let myEarningsRetailerListhead = table_Head;
  let myEarningsRetailerListdata = tabledata;
  return { myEarningsRetailerListhead, myEarningsRetailerListdata };
};

const ValueTransferReportList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === 'isReversal') {
            tempObj = {
              ...tempObj,
              isReversal: !tdata.isReversal ? 'Forward' : 'Reverse',
            };
          }

          if (dataproperty === 'destinationWallet') {
            tempObj = {
              ...tempObj,
              name: tdata['destinationWallet']?.user?.firstName + ' ' + tdata['destinationWallet']?.user?.lastName,
              mobile: tdata.destinationWallet?.user?.mobile,
              edgeId: tdata.destinationWallet?.user?.edgeId,
            };
          }
          if (dataproperty === 'transaction') {
            tempObj = { ...tempObj, amount: tdata['transaction']?.amount };
          }
        });
      }
      tabledata.push({ ...tempObj });
    });
  let ValueTransferReportListdata = tabledata;
  let ValueTransferReportListhead = table_Head;

  return { ValueTransferReportListdata, ValueTransferReportListhead };
};

export {
  TransactionListRecharge,
  TransactionListDMT,
  FundLoadRequestList,
  FundLoadCompleted,
  RetailerList,
  DistributorList,
  ValueTransferList,
  ValueTransferReportList,
  BuyKitsIssuedList,
  BuyKitsList,
  MyLedgerList,
  MyEarningsList,
  MyEarningsRetailerList,
  PaymentGatewayList,
};
