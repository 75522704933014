import BuyKitsModal from "./buyKitsModal/buyKitsModal";

const BuyKits = () => {
    return(
        <div className="m-5">
            <div className="relative bottom-20">
            <BuyKitsModal />
            </div>
        </div>
    )

}

export default BuyKits;