import { Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomModal from "../../../components/common/customModal/customModal";
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from "../../../hooks/dateFormatter";
import UseForm from "../../../hooks/useForm";
import { BuyKitsList } from "../../../hooks/useTableData";
import distributorService from "../../../services/distributor_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { Kitvalidation } from "../../../validation/validation";
import "./buykitsmodal.css";

const initialState = {
  kits: {
    value: "",
    required: true,
    regexp: /^[1-9][0-9]*$/,
    minLength: 1,
    minLengthMessage: 'At least 2 characters long!',
    maxLength: 2,
    maxLengthMessage: 'Too many characters!'
  },
};

const styleBtn = {
  textAlign: "center",
};

const buttonStyle = {
  width: "20%",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const tableHeadBuyKits = [
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    id: "date",
    format: (row) => DateFormatter(row.date, "table"),
  },
  {
    name: "Count",
    selector: (row) => row.count,
    sortable: true,
    id: "count",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "amount",
    //
  },
  {
    name: "Total Amount",
    selector: (row) => row.totalAmount,
    sortable: true,
    id: "totalAmount",
  },
];

const BuyKitsModal = () => {
  const [state, contextDispatch] = useContext(Context);
  if (localStorage.getItem("user" !== null)) {
    const token = localStorage.getItem("user");
    const data = JSON.parse(token)?.accessToken;
    var decoded = jwt_decode(data);
  }
  const [sendData, setSendData] = useState("");
  const [fetchedTableData, setFetchedTableData] = useState("");
  const [modalData, setModalData] = useState({
    amount: "",
    description: "",
    modalType: "buykits",
  });
  const [pending, setPending] = useState(true);

  function submitForm(value) {
    setSendData({ userId: decoded?.id, kitCount: values.kits.value });
    setModalData({
      description: `A total of ${(
        100 * values.kits.value
      ).toLocaleString()} rupees will be deducted from your account`,
      modalType: "buykits",
    });
    contextDispatch({
      type: Constants.CUSTOM_MODAL,
      payload: { isOpen: true, modalProps: {} },
    });
    clearState(initialState);
  }

  const { handleChange, values, handleSubmit, errors, clearState, resetKey } = UseForm(
    submitForm,
    Kitvalidation,
    initialState
  );

  const { kitsListdata, kitsListhead } = BuyKitsList(
    fetchedTableData,
    tableHeadBuyKits
  );

  useEffect(() => {
    setPending(true);
    distributorService.Buy_Kits_List().then((res) => {
      setPending(false);
      setFetchedTableData(res?.data?.data?.data);
    });
  }, [state[0]?.callApi]);

  const handleInputChange = (e) => {
    const pattern = /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    }
    else if (e.target.value === "") {
      resetKey(initialState.kits, "kits");
    }
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item sm={6}>
          <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div className="buykits-modal relative modal-transparency">
              <p className="text-center text-bluebutton kit-mgnt">
                Buy Retailer Activation Kits
              </p>
              <div className="buykits-btn mt-5 relative">
                <CustomInput
                  max={2}
                  style={styleBtn}
                  name="kits"
                  value={values.kits.value}
                  handleChange={handleInputChange}
                  placeholder={"Enter No. Of Kits"}
                />
                {errors.kits && (
                  <p className="absolute errorText">{errors.kits}</p>
                )}
              </div>
              <div className="buykits-modal-btn">
                <CustomButton style={buttonStyle} label={"Buy"} />
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
      <div className="mt-16 buykits-modal-table table-container">
        <CustomTable
          pending={pending}
          tabledata={kitsListdata}
          tablehead={kitsListhead}
        />
      </div>
      <CustomModal
        buttonLabel="Yes! Agree and Purchase"
        header={"Are you sure?"}
        data={sendData}
        name={modalData.modalType}
        description={modalData.description}
        modalType={modalData.modalType}
      />
    </>
  );
};

export default BuyKitsModal;
