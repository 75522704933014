import MyLedgerModal from "./myLedgerModal";

const MyLedger = () => {
  return (
    <div className="m-5">
      <div className="relative bottom-28">
        <MyLedgerModal />
      </div>
    </div>
  );
};

export default MyLedger;
