import MyEarningsModal from './myEarningsModal';

const MyEarnings = () => {
  return (
    <div className="m-5">
      <div className="relative bottom-28">
        <MyEarningsModal />
      </div>
    </div>
  );
};

export default MyEarnings;
