import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import SidebarControl from "../../../assets/svg/nav/sidebarControl.svg";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import "./sidebaropen.css";

const SidebarOpen = ({ handleSidebarType, Sidebar_Data }) => {
  const [openChild, setOpenChild] = useState("");
  const [state, contextDispatch] = useContext(Context);

  const showSubnav = (id) =>
    id
      ? openChild === id
        ? setOpenChild("")
        : setOpenChild(id)
      : setOpenChild("");

  return (
    <div className="flex">
      <div style={{ width: "328px" }} className="sidebar">
        <span className="logo"><img src={process.env.REACT_APP_SIDEBAR} alt="sidebar-open" /></span>
        <div className="sidebaropen-openicon">
          <img
            onClick={handleSidebarType}
            className='cursor-pointer'
            alt="SidebarControl"
            src={SidebarControl}
          />
        </div>
        <div className="sidebaropen-container">
          {Sidebar_Data?.map((item, i) => (
            < div id="sidebaropen-navlink-container" key={i} >
              <NavLink
                className={`${openChild === item?.navId ? "link active" : "link"
                  }`} // Parent link
                activeclassname="active"
                to={item.path}
                onClick={() => {
                  contextDispatch({
                    type: Constants.ACTIVE_TAB_ID,
                    payload: 1,
                  });
                  item.subNav ? showSubnav(item.navId) : showSubnav();
                }}
              >
                <div className="flex items-center justify-between w-full m-2.5">
                  <div className="flex items-center ">
                    <span className="icon">{item.icon}</span>
                    <span className="link_text pl-5">{item.title}</span>
                  </div>
                  {item.subNav ? (
                    openChild === item?.navId ? (
                      <span className="rotate-180">
                        <KeyboardArrowDownRoundedIcon />
                      </span>
                    ) : (
                      <span>
                        <KeyboardArrowDownRoundedIcon />
                      </span>
                    )
                  ) : null}
                </div>
              </NavLink>
              {openChild === item?.navId &&
                item?.subNav
                  ?.filter((sub) => sub?.chainType?.includes(JSON.parse(localStorage.getItem("user"))?.chainType))
                  .map((item, i) => {
                    return (
                      <NavLink
                        onClick={() => contextDispatch({ type: Constants.ACTIVE_TAB_ID, payload: 1 })}
                        className={`${openChild === item?.navId ? "link active" : "link"}`} //Need some changes
                        activeclassname="active"
                        to={item.path}
                        key={i}
                      >
                        <div className={"flex items-center m-2.5"}>
                          <span>{item.icon}</span>
                          <span className="link_text">{item.title}</span>
                        </div>
                      </NavLink>
                    );
                  })}
            </div>
          ))}
        </div>
      </div>
      {/* <main
        style={{ width: "calc(100% - 328px)", right: "-328px" }}
        className="relative top-0"
      >
        {children}
        <Copyright color={"text-bluebutton"} />
      </main> */}
    </div >
  );
};
export default SidebarOpen;
