import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomModal from "../../../components/common/customModal/customModal";
import UseForm from "../../../hooks/useForm.js";
import { TransferValuevalidation } from "../../../validation/validation";
import CustomSelect from "../../../components/common/customSelect/customSelect";
import CustomInput from "../../../components/common/customInput/customInput";
import distributorService from "../../../services/distributor_service";
import { Context } from "../../../stateManagement/context";
import { Constants } from "../../../stateManagement/constants";

const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
};

const initialState = {
  retailer: { value: "", required: true, id: "" },
  amount: {
    value: "", required: true, regexp: /^[1-9][0-9]*$/,
    minLength: 3,
    minLengthMessage: 'At least 3 characters long!',
    maxLength: 7,
    maxLengthMessage: 'Too many characters!'
  },
  reversal: { value: "", required: true, id: "" },
};

const selectDataReversal = [
  { value: true, label: "Forward" },
  { value: false, label: "Reverse" },
];

const TransferValue = () => {
  const [fetchedData, setFetchedData] = useState("");
  const [sendData, setSendData] = useState(null);
  const [state, contextDispatch] = useContext(Context);

  function submitForm() {
    setSendData({
      "toUserId": values.retailer.value.value,
      "value": Number(values.amount.value),
      "type": values.reversal.value
    });
    contextDispatch({
      type: Constants.CUSTOM_MODAL,
      payload: { isOpen: true, modalProps: {} },
    });
    clearState(initialState);
  }
  const { handleChange, selectChange, values, handleSubmit, errors, clearState, resetKey } = UseForm(
    submitForm,
    TransferValuevalidation,
    initialState
  );

  useEffect(() => {
    distributorService
      .Transfer_Value_View_Chain()
      .then((res) => setFetchedData(res.data.data));
  }, []);

  const selectData = [];
  fetchedData &&
    fetchedData.map((e) => {
      selectData.push({
        value: `${e.id}`,
        label: `${e.firstName?.charAt(0).toUpperCase() + e.firstName?.slice(1)} ${e.lastName?.charAt(0).toUpperCase() + e.lastName?.slice(1)} (${e.edgeId})`,
      });
    });

    const handleInputChange = (e) => {
      const pattern = /^[1-9][0-9]*$/;
      if (pattern.test(e.target.value)) {
        handleChange(e);
      }
      else if (e.target.value === "") {
        resetKey(initialState.amount, "amount");
      }
    }

  return (
    <Grid sm={12} md={12}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="flex justify-center mt-5 flex-col">
          <label className="valueTransfer-label my-3 pt-2">
            Choose Retailers
            <span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomSelect
              data={selectData}
              name="retailer"
              value={values.retailer.value}
              handleChange={selectChange}
              label="Choose Retailer / Number"
            />
            {errors.retailer && (
              <p className="absolute errorText">{errors.retailer}</p>
            )}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Transaction Type<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomSelect
              data={selectDataReversal}
              name="reversal"
              value={values.reversal.value}
              handleChange={selectChange}
              label="Choose Transaction Type"
            />
            {errors.reversal && (
              <p className="absolute errorText">{errors.reversal}</p>
            )}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Amount<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomInput
              max={7}
              name="amount"
              value={values.amount.value}
              handleChange={handleInputChange}
              placeholder={"Amount"}
            />
            {errors.amount && (
              <p className="absolute errorText">{errors.amount}</p>
            )}
          </div>
          <div className="flex justify-between  items-center mt-8">
            <label className="valueTransfer-label">
              {/* {`Your Balance Rs : ${fetchedBalance["data"]?.balance}`} */}
            </label>
            <CustomButton
              style={buttonStyle}
              label={"Submit"}
            />
          </div>
        </div>
      </form>
      <CustomModal
        buttonLabel={"Submit"}
        name="transferValue"
        header={"Enter T-PIN"}
        data={sendData}
        modalType="transferValue"
      />
    </Grid>
  );
};

export default TransferValue;
