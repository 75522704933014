import { lazy, Suspense } from 'react';
import Loader from '../components/common/loader/loader';
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
import Layout from '../Layout/Layout';
import BuyKits from '../pages/buyKits';
import Dashboard from '../pages/dashboard';
import CreateDistributor from '../pages/distributor-management/create-distributor';
import DistributorList from '../pages/distributor-management/list-distributor';
import FundLoad from '../pages/fundLoad';
import PageNotFound from '../pages/pageNotFound/pageNotFound';
import Payments from '../pages/payments';
import PaymentsVirtualAccount from '../pages/paymentsVirtualAccount';
import MyEarnings from '../pages/report/my-earnings';
import MyLedger from '../pages/report/my-ledger';
import CreateRetailer from '../pages/retailer-management/create-retailer';
import RetailerList from '../pages/retailer-management/retailer-list';
import Tpin from '../pages/tPin';
import Transactions from '../pages/transactions';
import TransferKits from '../pages/transferKits';
import ValueTransfer from '../pages/valueTransfer';
import ValueTransferReport from '../pages/valueTransferReport';
import ProtectedRoute from './protected-routes';
import { appRoutes } from './routes';
const Login = lazy(() => import('../pages/login/login'));

const routeConfig = [
  {
    path: appRoutes.UNKNOWN,
    element: <PageNotFound />,
  },
  {
    path: appRoutes.LOGIN,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: appRoutes.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Dashboard />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.PAYMENTS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Payments />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.VIRTUAL_ACCOUNT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <PaymentsVirtualAccount />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.VALUE_TRANSFER,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <ValueTransfer />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.VALUE_TRANSFER_REPORT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <ValueTransferReport />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.FUND_LOAD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <FundLoad />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREATE_RETAILER,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreateRetailer />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.RETAILER_LIST,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <RetailerList />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREATE_DISTRIBUTOR,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreateDistributor />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.DISTRIBUTOR_LIST,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <DistributorList />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TRANSACTION,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Transactions />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TPIN,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Tpin />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.MY_LEDGER,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <MyLedger />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.MY_EARNINGS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <MyEarnings />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.BUY_KITS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <BuyKits />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TRANSFER_KITS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <TransferKits />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
];

export default routeConfig;
