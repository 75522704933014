import CustomSnackbar from "../common/customSnackbar/customSnackbar";
import Popup from "../common/popUp/popUp";


const ModalProvider = ({ children }) => {

    return (
        <>
            <Popup />
            <CustomSnackbar />
            {/* <CustomLoader /> */}
            {children}
        </>
    )

}

export default ModalProvider