import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomSelect from "../../../components/common/customSelect/customSelect";
import CustomTab from "../../../components/common/customTab/customTab";
import CustomTable from "../../../components/common/customTable/customTable";
import UseForm from "../../../hooks/useForm.js";
import { RetailerList } from "../../../hooks/useTableData";
import APIConstants from "../../../services/constants";
import distributorService from '../../../services/distributor_service';
import { Constants } from '../../../stateManagement/constants';
import { RetailerListvalidation } from '../../../validation/validation';
import "./retailerlistmodal.css";
import { Context } from '../../../stateManagement/context';

const valuesTab = [
  { id: 1, text: "Retailer List" },
];

const tableHeadRetailerList = [
  {
    name: "User Id",
    selector: (row) => row.edgeId,
    sortable: true,
    id: "edgeId",
    
  },
  {
    name: "First Name",
    selector: (row) => row.firstName,
    sortable: true,
    id: "firstName",
    
  },
  {
    name: "Last Name",
    selector: (row) => row.lastName,
    sortable: true,
    id: "lastName",
    
  },
  {
    name: "Mobile",
    selector: (row) => row.mobile,
    sortable: true,
    id: "mobile",
    
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    id: "email",
    
  },
];

function RetailerListModal() {
  const [pending, setPending] = useState(true);
  const [fetchedData, setFetchedData] = useState("");
  const [state, contextDispatch] = useContext(Context);
  useEffect(() => {
    let mounted = true;
    const getTransferValueChain = async () => {
      const res = await distributorService.List_All_Type({ userType: APIConstants.RETAILER, listAll: false });
      if (mounted) {
        setPending(false);
        setFetchedData(res?.data?.data);
      }
    };

    getTransferValueChain().catch((err) => {
    });

    return () => {
      mounted = false;
      setPending(true);
    };
  }, []);

  const { tabledata, tablehead } = RetailerList(fetchedData, tableHeadRetailerList);

  const handleTabSelected = (val) =>
    contextDispatch({
      type: Constants.ACTIVE_TAB_ID,
      payload: val,
    });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <div className="modal-transparency" id="createRetailer-yourchain-modal-container">
            <CustomTab values={valuesTab} selected={handleTabSelected} />
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
      <div className="bg-white p-5 mt-10 table-container">
        <CustomTable tablehead={tablehead} pending={pending} tabledata={tabledata} />
      </div>
    </>
  );
}

export default RetailerListModal;
