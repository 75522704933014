import "./cutomTable.css";
import DataTable from "react-data-table-component";
import CustomLoader from '../customLoader/CustomLoader';

const customStyles = {
  table: {
    style: {
      width: "100%",
      borderRadius: "10px",
      overflow: "hidden",
      border: "1px solid #d2d2d6",
      borderSpacing: "0",
    },
  },
  head: {
    style: {
      color: "#112467",
      fontSize: "14px",
      fontWeight: 700,
      width: "auto",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
  },
  headRow: {
    style: {
    },
  },
  headCells: {
    style: {
      width: "auto",
      padding: "10px",
      fontSize: "14px",
      fontWeight: 700,
      textAlign: "left",
      color: "#112467",
      whiteSpace: "nowrap",
      borderRight: "1px solid #d2d2d6",
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  tableRow: {
    style: {
      borderBottom: "none",
    },
  },
  rows: {
    style: {
      fontSize: "13px",
      fontWeight: 400,
      "&:last-child": {

      },
    },
  },
  cells: {
    style: {
      wordBreak: "break-word",
      width: "auto",
      fontSize: "14px",
      fontWeight: 400,
      color: "#112467",
      textAlign: "left",
      padding: "10px",
      userSelect: "none",
      verticalAlign: "top",
      borderRight: "1px solid #d2d2d6",
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  pagination: {
    style: {
      border: "none",
      color: "#112467",
    },
  },
  progress: {
    style: {
      padding: "0.75rem 1rem",
    },
  },
};

const noDataConst = (
  <h1
    style={{
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "4px",
      color: "#000000",
      padding: "0.75rem 1rem",
    }}
  >
    NO DATA
  </h1>
);

function CustomTable({
  tablehead,
  tabledata,
  pending,
  nopagination
}) {
  return (
    <DataTable
      customStyles={customStyles}
      fixedHeader
      persistTableHead
      columns={tablehead}
      data={tabledata}
      pagination={!nopagination ? true : false}
      progressPending={pending}
      progressComponent={<CustomLoader />}
      noDataComponent={tabledata?.length === 0 && noDataConst}
    />
  );
}

export default CustomTable;
