import api from './api';

const token = localStorage.getItem('user');

console.log(JSON.parse(token)?.accessToken);

const Create_Distributor = (data) => api.post(`/distributor/create`, data);

const Create_Retailer = (data) => api.post(`/retailer/create`, data);

const List_All_Type = (data) => api.post(`/user/list`, data);

const Buy_Kits = (data) => api.post(`/ktm/buy`, data);

const Buy_Kits_List = () => api.post(`/distributor/kit/purchase/history`);

const Transfer_Kits = (data) => api.post(`/ktm/transfer`, data);

const Distributor_View_Child = () => api.post(`distributor/child/list`);

const Pan_Validation = (data) => api.post(`/user/pan/check`, data);

const Transfer_Value_View_Chain = (data) => api.post(`/user/chain`, data);

const Value_Transfer_Report = (data) => api.post(`/fund/value_transfer/history`, data);

const Transfer_Value_Forward = (data) => api.post(`/fund/transfer`, data);

const Transfer_Value_Backward = (data) => api.post(`/fund/transfer/reverse`, data);

const Tpin_Generate = () => api.post(`/user/t-pin/sendOtp`);

const TpinSet = (data) => api.post(`/user/tpin/set`, data);

const Tpinverify = (data) => api.post(`/user/tpin/verify`, data);

const GetProfile = () => api.get(`/user/profile/get`);

const Transfer_Value = (data) => api.post(`/fund/transfer/request`, data);

const Payment_Gateway = (data) => api.post(`/pg/rz/link/create`, data);

const FundLoad_Req = (data) => api.post(`/fund/request/list`, data);

const Value_Transfer_List = (data) => api.post(`/fund/requester/list`, data);

const Value_Transfer_Accept = (data) => api.post(`/fund/request/accept`, data);

const Value_Transfer_Reject = (data) => api.post(`/fund/request/reject`, data);

const Transaction_List_Table = (data) => api.post('distributor/list/transaction', data);
const Buy_Kits_Issued = () => api.post(`/distributor/list/kit/issued`);

const Kit_Balance = () => api.get(`distributor/kit/available`);

const My_Ledger = (data) => api.post(`distributor/list/myLedger`, data);

const My_Earnings = (data) => api.post(`/retailer/get/earning`, data);

const Get_Virtual_Account = (data) => api.get(`pg/rz/myaccount`, data);

const Value_Received = (data) => api.post(`fund/value_transfer/history`, data);

const Forget_Password_Request = (data) => api.post(`auth/otp/request`, data);

const Change_Password = (data) => api.post(`auth/resetpassword`, data);

const Login_Set_New_Password = (data) => api.post(`/auth/setnewpassword`, data);

const Forgot_Password_OTP_Verify = (data) => api.post(`/auth/verify`, data);

const Payment_Gateway_Razor_Pay = (data) => api.post(`/pg/rz/link/create`, data);

const Payment_Gateway_CashFree_Pay = (data) => api.post(`/pg/cf/paylink/create`, data);

const Dashboad_Chainbalance = (data) => api.post(`user/list/chain/balance`);

const Payment_Mode = () => api.get(`/fund/list/payment/mode`);

const Payment_Banks = () => api.get(`/fund/list/payment/bank`);

const Request_WFP = (data) => api.post(`/fund/request/wfp`, data);

const Payment_Gateway_List = (data) => api.post(`/pg/list/fundloads`, data);

const distributorService = {
  Create_Distributor,
  Create_Retailer,
  List_All_Type,
  Buy_Kits,
  Buy_Kits_List,
  Transfer_Kits,
  Distributor_View_Child,
  Pan_Validation,
  Transfer_Value_View_Chain,
  Transfer_Value_Forward,
  Transfer_Value_Backward,
  Tpin_Generate,
  TpinSet,
  GetProfile,
  Transfer_Value,
  Tpinverify,
  Payment_Gateway,
  FundLoad_Req,
  Value_Transfer_Accept,
  Value_Transfer_Reject,
  Transaction_List_Table,
  Buy_Kits_Issued,
  Kit_Balance,
  My_Ledger,
  My_Earnings,
  Get_Virtual_Account,
  Value_Received,
  Value_Transfer_List,
  Forget_Password_Request,
  Change_Password,
  Login_Set_New_Password,
  Forgot_Password_OTP_Verify,
  Payment_Gateway_Razor_Pay,
  Payment_Gateway_CashFree_Pay,
  Value_Transfer_Report,
  Dashboad_Chainbalance,
  Payment_Mode,
  Payment_Banks,
  Request_WFP,
  Payment_Gateway_List,
};

export default distributorService;
