import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomSelect from '../../../components/common/customSelect/customSelect';
import CustomTab from '../../../components/common/customTab/customTab';
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from '../../../hooks/dateFormatter';
import { MyEarningsList, MyEarningsRetailerList } from "../../../hooks/useTableData";
import distributorService from '../../../services/distributor_service';
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import MyEarnings from "./myEarnings";
import "./myearningsmodal.css";
import MyEarningsRetailer from './myEarningsRetailer';

const values = [
  { id: 1, text: "My Earnings" },
  { id: 2, text: "My Earnings Retailer" },
];

const selectObj = [{ value: "dmt", label: "DMT" }, { value: "recharge", label: "Recharge" }, { value: "credit card", label: "Credit Card" }]

const tableheadMyEarningsRetailer = [
  {
    name: "Transaction At",
    selector: (row) => row.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row.createdAt, "table"),
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "amount",
  },
  {
    name: "Commission",
    selector: (row) => row.commission,
    sortable: true,
    id: "commission",
  },

  {
    name: "Transaction Ref",
    id: "transactionRef",
    width: "200px !important",
    cell: (row) => (<div className='w-full'>{row.transactionRef}</div>)
  },
];

const tableheadMyEarnings = [
  {
    name: "Transaction At",
    selector: (row) => row.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row.createdAt, "table"),
  },
  {
    name: "Total Amount",
    selector: (row) => row.totalAmount,
    sortable: true,
    id: "totalAmount",
  },
  {
    name: "Commission",
    selector: (row) => row.commission,
    sortable: true,
    id: "commission",
  },
];


const MyEarningsModal = () => {
  const [fetchedTableData, setFetchedTableData] = useState({ recharge: [], ecaps_dmt: [], credit_card: [] });
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState(null);
  const [fetchedData, setFetchedData] = useState("");
  const [tableType, setTableType] = useState({ value: "dmt", label: "DMT" });

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

  const [dateObj, setDateObj] = useState({ startDate: startDate, endDate: today });


  const handleTabSelected = (val) => {
    setFetchedTableData([]);
    contextDispatch({ type: Constants.ACTIVE_TAB_ID, payload: val });
  };

  const [selectedData, setSelectedData] = useState({ startDate: startDate, endDate: endDate, userId: "" });

  const setData = (data) => {
    setSelectedData(data);
  };


  useEffect(() => {
    setDateObj({ startDate: startDate, endDate: today });
    setSelectedData({ startDate: startDate, endDate: endDate, userId: "" });
    setPending(true);
    setFetchedTableData([]);
    switch (state[0]?.activeTabId) {
      case 1:
        setTableType({ value: "dmt", label: "DMT" });
        distributorService.My_Earnings({ startDate: DateFormatter(new Date(selectedData.startDate), 'payload'), endDate: DateFormatter(new Date(selectedData.endDate), 'payload') })
          .then((res) => {
            setPending(false);
            setFetchedTableData(res?.data?.data);
          }).catch((err) => setPending(false))
        break;
      case 2:
        setTableType({ value: "dmt", label: "DMT" });
        distributorService.My_Earnings({ startDate: DateFormatter(new Date(selectedData.startDate), 'payload'), endDate: DateFormatter(new Date(selectedData.endDate), 'payload'), userId: selectedData?.userId })
          .then((res) => {
            setPending(false);
            setFetchedTableData(res?.data?.data);
          }).catch((err) => setPending(false))
        break;
      default:
        break;
    }
  }, [state[0]?.callApi]);

  useEffect(() => {
    distributorService
      .Transfer_Value_View_Chain({ userType: "retailer" })
      .then((res) => {
        setFetchedData(res.data.data);
        setSelectedData((prev) => ({ ...prev, userId: res.data.data[0]?.id }));
      });
  }, []);

  const selectData = [];
  fetchedData &&
    fetchedData.map((e) => {
      selectData.push({
        value: `${e.id}`,
        label: `${e.firstName?.charAt(0).toUpperCase() + e.firstName?.slice(1)} ${e.lastName?.charAt(0).toUpperCase() + e.lastName?.slice(1)} (${e.edgeId})`,
      });
    });

  function RenderTabs(tabId) {
    switch (tabId) {
      case 1:
        return (
          <MyEarnings setData={setData} dateObj={dateObj} />);
      case 2:
        return (
          <MyEarningsRetailer selectData={selectData} setData={setData} />);
      default:
        return (
          <MyEarnings setData={setData} dateObj={dateObj} />)
    }
  }

  function RenderTables(id) {
    switch (id) {
      case 1:
        if (tableType.value === "dmt") {
          const { myEarningsListhead, myEarningsListdata } = MyEarningsList(fetchedTableData['ecaps_dmt'], tableheadMyEarnings);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsListhead} pending={pending} tabledata={myEarningsListdata} />
          </>);
        }
        if (tableType.value === "recharge") {
          const { myEarningsListhead, myEarningsListdata } = MyEarningsList(fetchedTableData['recharge'], tableheadMyEarnings);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsListhead} pending={pending} tabledata={myEarningsListdata} />
          </>);
        }
        if (tableType.value === "credit card") {
          const { myEarningsListhead, myEarningsListdata } = MyEarningsList(fetchedTableData['credit_card'], tableheadMyEarnings);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsListhead} pending={pending} tabledata={myEarningsListdata} />
          </>);
        }
      case 2:
        if (tableType.value === "dmt") {
          const { myEarningsRetailerListhead, myEarningsRetailerListdata } = MyEarningsRetailerList(fetchedTableData['ecaps_dmt'], tableheadMyEarningsRetailer);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsRetailerListhead} pending={pending} tabledata={myEarningsRetailerListdata} />
          </>);
        }
        if (tableType.value === "recharge") {
          const { myEarningsRetailerListhead, myEarningsRetailerListdata } = MyEarningsRetailerList(fetchedTableData['recharge'], tableheadMyEarningsRetailer);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsRetailerListhead} pending={pending} tabledata={myEarningsRetailerListdata} />
          </>);
        }
        if (tableType.value === "credit card") {
          const { myEarningsRetailerListhead, myEarningsRetailerListdata } = MyEarningsRetailerList(fetchedTableData['credit_card'], tableheadMyEarningsRetailer);
          return (<>
            <h1 className='text-[#112467] my-2.5 font-bold'>{`My Earnings ${tableType.label} List`}</h1>
            <CustomTable tablehead={myEarningsRetailerListhead} pending={pending} tabledata={myEarningsRetailerListdata} />
          </>);
        }
      default:
        break;
    }
  }

  const handleChangeSelect = (e) => {
    setTableType(e.value);
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item sm={10}>
          <div className="myledger-modal-container modal-transparency">
            <CustomTab values={values} selected={handleTabSelected} />
            {RenderTabs(state[0]?.activeTabId)}
          </div>
        </Grid>
      </Grid>
      <div className="bg-white p-5 mt-10 table-container">
        <span className='w-full flex items-center justify-end'>
          <span>Select Table Type</span>
          <span className='ml-8 w-1/5'><CustomSelect
            data={selectObj}
            name="list"
            value={tableType}
            handleChange={handleChangeSelect}
            label={tableType?.label}
          /></span>
        </span>
        {RenderTables(state[0]?.activeTabId)}
      </div>
    </>
  );
};

export default MyEarningsModal;
