import { Grid } from "@mui/material";
import { useContext, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomTab from "../../../components/common/customTab/customTab";
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from "../../../hooks/dateFormatter";
import { ValueTransferReportList } from "../../../hooks/useTableData";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import TransferRetailer from "../transferRetailer/transferRetailer";
import ValueReceived from "../valueReceived/valueReceived";
import "./valuetransferreportmodal.css";
import serviceConstants from "../../../services/constants";


const values = [
  { id: 1, text: "Value Transfer" },
  { id: 2, text: "Transfer to Retailer" },
];

const fontStyle = {
  fontSize: "14px",
};

const exportButtonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
  fontSize: "14px",
  fontWeight: 800,
  color: "#112467",
  marginTop: "20px",
};

const tablehead = [
  {
    name: "Id",
    selector: (row) => row.edgeId,
    sortable: true,
    id: "edgeId",
    // width: "180px !important",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    id: "name",
    // width: "180px !important",
  },
  {
    name: "Mobile No",
    selector: (row) => row.mobile,
    sortable: true,
    id: "mobile",
    // width: "180px !important",
  },
  {
    name: "Transaction Type",
    selector: (row) => row.isReversal,
    sortable: true,
    id: "isReversal",
    // width: "180px !important",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "Amount",
    // width: "180px !important",
  },
];

const ValueTransferReportModal = () => {
  const tableRef = useRef(null);
  const [pending, setPending] = useState(true)
  const [state, contextDispatch] = useContext(Context);
  const [fetchedSelectData, setFetchedSelectData] = useState("");

  let dateObj = { startDate: "", endDate: "" };

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  dateObj = ({ startDate: startDate, endDate: today });

  const handleTabSelected = (val) => contextDispatch({
    type: Constants.ACTIVE_TAB_ID,
    payload: val,
  });

  const [fetchTableData, setFetchTableData] = useState(null);

  const fetchDataFromApi = (data) => {
    setFetchTableData(data);
  };

  useEffect(() => {
    distributorService.Transfer_Value_View_Chain({ userType: serviceConstants.RETAILER }).then((data) => {
      setFetchedSelectData(data?.data?.data)
    })
  }, [])

  const selectData = [];
  fetchedSelectData &&
    fetchedSelectData.map((e) => {
      selectData.push({
        value: `${e.id}`,
        label: `${e.firstName?.charAt(0).toUpperCase() + e.firstName?.slice(1)} ${e.lastName?.charAt(0).toUpperCase() + e.lastName?.slice(1)} (${e.edgeId})`,
      });
    });

  function RenderTab(value) {
    switch (value) {
      case 1:
        return <ValueReceived dateObj={dateObj} fetchDataFromApi={fetchDataFromApi} />;
      case 2:
        return <TransferRetailer dateObj={dateObj} selectData={selectData} fetchDataFromApi={fetchDataFromApi} />;
      default:
        return <ValueReceived />;
    }
  }

  useEffect(() => {
    let mounted = true;

    switch (state[0]?.activeTabId) {
      case 1:
        setFetchTableData([]);
        distributorService.Value_Received({
          startDate: DateFormatter(new Date(startDate), 'payload'),
          endDate: DateFormatter(new Date(endDate), 'payload'),
        }).then((res) => {
          setPending(false)
       
          setFetchTableData(res?.data?.data);
        });
        break;
      case 2:
        setFetchTableData([]);
        distributorService.Value_Received({
          startDate: DateFormatter(new Date(startDate), 'payload'),
          endDate: DateFormatter(new Date(endDate), 'payload'),
          retailerId: selectData[0]?.value
        }).then((res) => {

          setPending(false)
          setFetchTableData(res?.data?.data);
        });
        break;
      default:
        break;
    }



    return () => {
      mounted = false
      setPending(true)
    }
  }, [state[0]?.activeTabId]);



  const { ValueTransferReportListdata, ValueTransferReportListhead } =
    ValueTransferReportList(fetchTableData, tablehead);


  const Thead = ValueTransferReportListhead.map((head) => {
    return ({ label: head.name, key: head.name })
  })

  const Tbody = ValueTransferReportListdata.map((body) => {
    return ({ "Id": body.edgeId, "Name": body.name, "Mobile No": body.mobile, "Transaction Type": body.isReversal, "Amount": body.amount })
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <div className="value-transfer-report-container modal-transparency">
            <CustomTab
              style={fontStyle}
              values={values}
              selected={handleTabSelected}
            />
            {RenderTab(state[0]?.activeTabId)}
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
      <div className="mt-10 bg-white p-5 rounded-xl table-container">
        <CustomTable
          tableRef={tableRef}
          pending={pending}
          tablehead={ValueTransferReportListhead}
          tabledata={ValueTransferReportListdata}
        />
        <CSVLink filename={"ValueTransferReport.csv"} data={Tbody} headers={Thead}>
          <CustomButton
            style={exportButtonStyle}
            variant="export"
            label="Download to excel"
          />
        </CSVLink>
      </div>
    </>
  );
};

export default ValueTransferReportModal;
