import { Grid } from "@mui/material";
import "./PaymentsVirtualAccountModal.css";
import CustomTable from "../../../components/common/customTable/customTable";
import CustomButton from "../../../components/common/customButton/customButton";
import { VirtualAccountvalidation } from "../../../validation/validation";
import { useState } from "react";
import UseForm from "../../../hooks/useForm";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomSelect from "../../../components/common/customSelect/customSelect";
import PGMoney from "../../../../src/assets/money.svg";
import { useEffect } from "react";
import distributorService from "../../../services/distributor_service";

const selectData = [
  { value: "sbi", label: "SBI" },
  { value: "icic", label: "ICIC" },
  { value: "yes bank", label: "YES BANK" },
];
const initialState = {
  amount: { value: "", required: true, regexp: /\D/g },
  pg: { value: "", required: true },
};

const tablehead = [
  {
    id: "Type",
    value: "Type",
  },
  {
    id: "Descriptions",
    value: "Descriptions",
  },
  {
    id: "Charges",
    value: "Charges",
  },
];

const tabledata = [
  {
    col_1: "Credit Card 1",
    col_2: "Any Bank",
    col_3: "1.30%",
  },
  {
    col_1: "Credit Card 2",
    col_2: "Any Bank",
    col_3: "1.30%",
  },
  {
    col_1: "Credit Card 3",
    col_2: "Any Bank",
    col_3: "1.30%",
  },
  {
    col_1: "Credit Card 4",
    col_2: "Any Bank",
    col_3: "1.30%",
  },
  {
    col_1: "Credit Card 5",
    col_2: "Any Bank",
    col_3: "1.30%",
  },
];

const buttonStyle = {
  width: "100%",
  marginTop: "38px",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const selectStyle = {
  // padding: "10px 10px",
  borderRadius: "12px",
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  height: "42px",
  width: "100%",
};

const tableStyle = {
  color: "text-bluebutton",
};

function PaymentsVirtualAccountModal() {
  const rowSpanData = `Minimum Amount Rs. ${100}/-`;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ accountNumber: "", ifsc: "" });

  function submitForm(value) {
    setIsSubmitting(value);
    setOpen(true);
  }

  const { handleSubmit, errors } = UseForm(
    submitForm,
    VirtualAccountvalidation,
    initialState
  );

  useEffect(() => {
    distributorService.Get_Virtual_Account().then((data) => {
      setValue(data.data.data)
    }).catch((err) => {
    })
  }, []);

  return (
    <Grid container justifyContent="center" alignContent="center" spacing={2}>
      <Grid item sm={6}>
        <div id="payment-virtual-modal" className="modal-transparency">
          <p className="text-center" id="payment-virtual-modal-title">
            Virtual Account Info
          </p>

          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="mt-4">
              <label>Account Number</label>
              <CustomInput
                name="amount"
                value={value?.accountNumber}
                // handleChange={handleChange}
                placeholder={"Account Number"}
                disabled
              />
              {errors.amount && (
                <p className="absolute errorText">{errors.amount}</p>
              )}
            </div>
            <div className="mt-4">
              <label>IFSC</label>
              <CustomInput
                name="amount"
                value={value?.ifsc}
                // handleChange={handleChange}
                placeholder={"IFSC"}
                disabled
              />
              {errors.amount && (
                <p className="absolute errorText">{errors.amount}</p>
              )}
            </div>
            {/* <div className="mt-9">
              <CustomSelect
                style={selectStyle}
                icon={PGMoney}
                data={selectData}
                name="pg"
                value={values.pg.value}
                handleChange={selectChange}
                label="PG Not Activated"
              />
              {errors.pg && <p className="absolute errorText">{errors.pg}</p>}
            </div> */}
            {/* <CustomButton
              style={buttonStyle}
              label={"Virtual Account Not Created"}
            /> */}
          </form>

          <div className="py-5">
            {/* <CustomTable
              style={tableStyle}
              tablehead={tablehead}
              tabledata={tabledata}
              rowSpanData={rowSpanData}
            /> */}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default PaymentsVirtualAccountModal;
