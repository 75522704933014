function DateFormatter(date, format) {
  switch (format) {
    case 'payload':
      var getDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      const tempDate1 = getDate.split('/');
      let day1 = tempDate1[0];
      let month1 = tempDate1[1];
      let year1 = tempDate1[2];
      if (day1 < 10) {
        day1 = `0${tempDate1[0]}`;
      }
      if (month1 < 10) {
        month1 = `0${tempDate1[1]}`;
      }
      const newDate1 = `${month1} . ${day1} . ${year1}`;
      return newDate1;

    case 'table':
      const tempDate = new Date(date).toLocaleDateString('en-IN', { timeZone: 'Asia/Jakarta' }).split('/');
      let day = tempDate[0];
      let month = tempDate[1];
      let year = tempDate[2];
      if (day < 10) {
        day = `0${tempDate[0]}`;
      }
      if (month < 10) {
        month = `0${tempDate[1]}`;
      }
      const newDate = `${day}/${month}/${year}`;
      return newDate;
    default:
      break;
  }
}

export default DateFormatter;
