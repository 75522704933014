import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from '../../../components/common/customButton/customButton';
import CustomDatePicker from "../../../components/common/customDatePicker/customDatePicker";
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from "../../../hooks/useForm";
import distributorService from '../../../services/distributor_service';
import { ValueReceivedvalidation } from "../../../validation/validation";
import "./ledger.css";

const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
};

const Ledger = ({ fetchDataFromApi, dateObj, setPending }) => {

  const initialState = {
    startDate: { value: dateObj['startDate'], required: true },
    endDate: { value: dateObj['endDate'], required: true },
  };

  const { values, handleSubmit, errors, dateChange } = UseForm(
    submitForm,
    ValueReceivedvalidation,
    initialState
  );

  function submitForm() {
    setPending(true);

    var tempDate = new Date(values.endDate.value);
    var endDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + 1);

    distributorService
      .My_Ledger({
      startDate: DateFormatter(new Date(values.startDate.value), 'payload'),
      endDate: DateFormatter(new Date(endDate), 'payload'),
      })
      .then((res) => {
        setPending(false);
        fetchDataFromApi(res?.data?.data);
      }).catch((err) => setPending(false))
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        md={12}
        container
        columnSpacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid md={4} className="my-5">
          <div className="flex justify-center flex-col">
            <CustomDatePicker
              dateChange={dateChange}
              name="startDate"
              selected={values.startDate.value}
              placeholder={"start Date"}
            />
            {errors.startDate && (
              <p className="absolute mt-16 errorText">{errors.startDate}</p>
            )}
          </div>
        </Grid>
        <Grid md={4} className="my-5">
          <div className="flex justify-center flex-col">
            <CustomDatePicker
              minDate={values.startDate.value}
              dateChange={dateChange}
              name="endDate"
              selected={values.endDate.value}
              placeholder={"end Date"}
            />
            {errors.endDate && (
              <p className="absolute mt-16 errorText">{errors.endDate}</p>
            )}
          </div>
        </Grid>
        <div className="ledger-btn">
          <CustomButton style={buttonStyle} label={"Get My Ledger"} />
        </div>
      </Grid>
    </form>
  );
};

export default Ledger;
